var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "card-body" }, [
        _c(
          "div",
          { staticClass: "table-responsive mb-0 shadow" },
          [
            _c("dataset", {
              attrs: { "ds-data": _vm.List_Staff },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var ds = ref.ds
                    return [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-6 mb-2 mb-md-0" })
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("div", {}, [
                            _c(
                              "table",
                              { staticClass: "table table-hover d-md-table" },
                              [
                                _c("thead", [
                                  _c(
                                    "tr",
                                    _vm._l(_vm.cols, function(th) {
                                      return _c("th", { key: th.field }, [
                                        _vm._v(" " + _vm._s(th.name) + " ")
                                      ])
                                    }),
                                    0
                                  )
                                ]),
                                _c("dataset-item", {
                                  attrs: { tag: "tbody" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var row = ref.row
                                          return [
                                            _c(
                                              "tr",
                                              {
                                                staticStyle: {
                                                  cursor: "pointer"
                                                }
                                              },
                                              [
                                                _c("td", [
                                                  _vm._v(_vm._s(row.last_name))
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(row.first_name) + " "
                                                  )
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(row.phone))
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(row.email))
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(row.postTitle))
                                                ])
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ])
                        ])
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-md-row flex-column justify-content-between align-items-center"
                        },
                        [
                          _c("dataset-show", {
                            attrs: { "ds-show-entries": 5 }
                          }),
                          _c("dataset-pager")
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }