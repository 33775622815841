<script>
import Layout from "../../../../../layouts/main";
import PageHeader from "@/components/page-header";
//import supliersTable from "./components/supliersTable";
import Swal from "sweetalert2";

import appConfig from "@/app.config";
import globalSupliersInfos from "./components/globalSupliersInfos";
import regularSupliersInfos from "./components/regularSupliersInfos";
import staffSupliersInfos from "./components/staffSupliersInfos";
//import staffImportInfos from './components/staffImportInfos';
import {
  fetchSuppliersListApi,
  fetchPaginateSuppliersListApi,
} from "@/api/common";
import Dataset from "vue-dataset/dist/es/Dataset.js";
import DatasetItem from "vue-dataset/dist/es/DatasetItem.js";
// import DatasetInfo from 'vue-dataset/dist/es/DatasetInfo.js'
// import DatasetPager from 'vue-dataset/dist/es/DatasetPager.js'
// import DatasetSearch from 'vue-dataset/dist/es/DatasetSearch.js'
import DatasetShow from "vue-dataset/dist/es/DatasetShow.js";

export default {
  import: { fetchSuppliersListApi, fetchPaginateSuppliersListApi },

  page: {
    title: "Prestataires et Fournisseurs",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    globalSupliersInfos,
    regularSupliersInfos,
    staffSupliersInfos,
    Dataset,
    DatasetItem,
    /* DatasetInfo,*/ /* DatasetSearch,*/ DatasetShow,
  },
  data() {
    return {
      defaultShow: 5,
      selectedSupplier: null,
      tableData: [],
      List_Suplier: [],
      suppliersPaginator: {},
      rows: "",
      active: false,
      selectedRows: [],
      clearGenInfo: undefined,
      refrechTable: undefined,
      firstStepDisabledDone: undefined,
      firstStepDisabled: undefined,
      globalForm: undefined,
      gotofirstStep: false,
      nextIndex: false,
      deleteSupp: undefined,
      currentSelectedStaffs: undefined,
      currentSelectedSupplier: undefined,
      title: "Prestataires et fournisseurs",
      tableindex: 0,
      id: undefined,
      uuid: undefined,
      //data:undefined,
      items: [
        {
          text: "Master Data",
          to: { name: "master_data" },
        },
        {
          text: "Referentiels",
          to: { name: "master_data.referentiels" },
        },
        {
          text: "Entreprise Externe",
          to: { name: 'master_data.referentiels.extreme_business' }
        },
        {
          text: "Prestataires et fournisseurs",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "type", sortable: true },
        { key: "classe", sortable: true },
      ],
      columns: [
        { title: "LName", name: "lname" },
        { title: "FName", name: "fname" },
        { title: "Email", name: "email" },
        { title: "Function", name: "function" },
      ],
      cols: [
        {
          name: "Prestataire ",
          field: "company_adr",
          sort: "",
        },
        {
          name: "Email d'employe",
          field: "first_name",
          sort: "",
        },
        {
          name: "Status entreprise",
          field: "email",
          sort: "",
        },
        {
          name: "Télèphone",
          field: "phone",
          sort: "",
        },
      ],
      selectedIndex: -1,
      resetBtnProps: false,
      addBtnProps: false,
      editBtnProps: false,
      deleteBtnProps: false,
      disabled: true,
      canDelte: false,
      disabledAdd: false,
      disabledUpdate: true,
      resetBtn: false,
    };
  },

  mounted() {
    // Set the initial number of items
    this.totalRows = this.tableData.length;

    // fetch divisions from api
    this.fetchSupList();
  },

  watch: {
    resetBtn: {
      handler(val) {
        this.addBtnProps = false;
        this.editBtnProps = false;
        this.deleteBtnProps = false;
        if (val) this.resetBtnSidebutton();
      },
    },
    firstStepDisabled: {
      handler(val) {
        this.firstStepDisabledDone = val;
      },
    },

    gotofirstStep: {
      handler(val) {
        if (val) {
          this.tableindex = 0;
          this.gotofirstStep = false;
        }
      },
    },
    nextIndex: {
      handler(val) {
        if (val) {
          this.tableindex += 1;
          this.nextIndex = false;
        }
      },
    },
    deleteSupp: {
      handler(val) {
        this.deleteSuppliers(val["name"], val["uuid"]);
      },
    },
    // globalForm:{
    //     handler(val){
    //       this.tableindex = 1;
    //     }
    // },

    refrechTable: {
      handler(val) {
        this.fetchSupList();
        this.refrechTable = false;
        // this.clearGenInfo = true;
      },
    },
    id: {
      handler(val) {
        this.tableindex = 2;
      },
    },

    selectedRows() {
      this.disabledAdd = false;
      this.disabledUpdate = true;
      if (this.selectedRows.length) {
        this.selectedSupplier = this.selectedRows[0];
        this.tableindex = 0;
        this.disabledUpdate = false;
      } else {
        this.selectedSupplier = [];
      }
    },
    suppliers: {
      handler(val) {
        this.List_Suplier.push({
          suplierPrestname: val.prestname,
          suplierEmail: val.email,
          statut_entreprise: val.statut_entreprise,
          suplierPhone: val.phone,
        });
      },
    },
  },
  methods: {
    resetBtnSidebutton() {
      this.disabled = true;
      this.canDelte = false;
      this.disabledAdd = false;
      this.disabledUpdate = true;
      this.tableindex = 0;
      this.resetBtnProps = false;
    },
    addBtn() {
      this.disabledUpdate    = true;
      this.disabledAdd    = true;
      this.editBtnProps = false;
      this.deleteBtnProps = false;
      this.addBtnProps = true;
      this.tableindex = 0;
      // this.addBtnProps = false;
    },
    editBtn() {
      this.addBtnProps    = false;
      this.deleteBtnProps = false;
      this.editBtnProps   = true;
      this.tableindex     = 0;
      this.disabledUpdate    = true;
      this.disabledAdd    = true;
      this.disabledEdit   = true;
      this.disabled       = false;
      this.disabledCancel = true;

      // this.editBtnProps = false;
    },
    deleteBtn() {
      this.addBtnProps = false;
      this.editBtnProps = false;
      this.deleteBtnProps = false;
      this.deleteBtnProps = true;
      this.disabledUpdate    = true;
      this.disabledAdd    = true;

      // this.deleteBtnProps = false;
    },

    selectRow(row, index) {
      if (this.selectedIndex == index) {
        this.selectedRows = [];
        this.selectedIndex = -1;
      } else {
        this.selectedRows = [row];
        this.selectedIndex = index;
      }
    },

    onShowChange(event){
      var loader = this.$loading.show();
      var show = this.defaultShow;
      if(!event){
        show = this.defaultShow;
      }else{
        show = event;
      }
      this.$http
        .get("/contracts/suppliers/paginate?show=" + show)
        .then((res) => {
          loader.hide();
          this.List_Suplier = res.data;
          this.suppliersPaginator = res.data;
          this.resetBtnProps = true;
          this.selectedRows = []; this.selectedIndex = -1;

        })
        .catch(() => {
          // error.response.status Check status code
          loader.hide();
        })
        .finally(() => {
          loader.hide();
          //Perform action in always
        });
    },


    fetchSupList(page = 1) {
      var loader = this.$loading.show();
      this.$http
        .get("/contracts/suppliers/paginate?page=" + page)
        .then((res) => {
          loader.hide();
          this.List_Suplier = res.data;
          this.suppliersPaginator = res.data;
          this.resetBtnProps = true;
          this.selectedRows = []; this.selectedIndex = -1;
          this.disabledAdd = false;


        })
        .catch(() => {
          // error.response.status Check status code
          loader.hide();
        })
        .finally(() => {
          loader.hide();
          //Perform action in always
        });
    },

    refreshSupplierst() {
      this.fetchSupList();
    },
    deleteSuppliers(typeName, typeUid) {
      var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer :" + typeName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/contracts/suppliers/delete/" + typeUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  _this.refreshSupplierst();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
  },
};
</script>

<style >
  .finish-button{
    background-color:#43A047 !important;
    border-color: #43A047 !important;
  }
  @media screen and (-webkit-min-device-pixel-ratio:0) {

    /*Chrome CSS here*/
    #StickyGSI {
    position: fixed;
    right: 0;
    top: 50%;
    width: 4em;
    margin-top: -2.5em;
    z-index: 999;
    list-style: none;
  }
    
}

@supports (-moz-appearance:none){ 
  #StickyGSI {
    position: fixed;
    right: 0;
    top: 50%;
    width: 1em;
    margin-top: -2.5em;
    z-index: 999;
    list-style: none;
  }
}
  .rounded-pillGSI{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 50% !important;
  }
  .btn-addGSI{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #044A72 ;
  }
  .btn-duplicateGSI{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #8FC412 ;
  }
  .btn-editGSI{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #FFB236 ;
  }
  .btn-deleteGSI #dropdown-dropleft__BV_toggle_{
     border-radius: 50% !important;
  }
  .btn-deleteGSI{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #D61337;
  }
  .btn-uploadGSI{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #2A5769 ;
  }
  .btn-downloadGSI{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #21BF92;
  }
  .btn-printGSI{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #B8CCD7 ;
  }

.champ {
  padding-right: -10px;
  margin-right: -10px;
}
</style>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- contracts header with table and search -->
    <div class="row">
      <div class="col-12">
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button
                block
                @click="active = !active"
                style="background:#e5e9f0; color:black !important; border:none !important;"
              >
                <div class="row">
                  <div class="col text-left mt-1">
                    <i class="mdi mdi-format-list-bulleted"></i> Liste des
                    Prestataires
                  </div>
                  <div class="col text-right">
                    <i
                      v-if="active"
                      class="mdi mdi-chevron-up font-size-18"
                    ></i>
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i>
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse
              id="accordion-1"
              :visible="active"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body class="shadow-lg">
                <div class="table-responsive mb-0 shadow">
                  <dataset v-slot="{ ds }" :ds-data="List_Suplier.data">
                    <div class="row">
                      <div class="col-md-6 mb-2 mb-md-0"></div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="">
                          <table class="table table-hover d-md-table">
                            <thead>
                              <tr>
                                <th v-for="th in cols" :key="th.field">
                                  {{ th.name }}
                                </th>
                              </tr>
                            </thead>
                            <dataset-item tag="tbody">
                              <template #default="{ row, rowIndex }">
                                <tr
                                  @click="selectRow(row, rowIndex)"
                                  :class="{
                                    'bg-soft-info': selectedIndex == rowIndex,
                                  }"
                                  style="cursor:pointer"
                                >
                                  <td>{{ row.prestname }}</td>
                                  <td>{{ row.email }}</td>
                                  <td>{{ row.statut_entreprise }}</td>
                                  <td>{{ row.phone }}</td>
                                </tr>
                              </template>
                            </dataset-item>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div
                      class="justify-content-between align-items-center"
                    >
                      <!-- <dataset-info class="mb-2 mb-md-0" /> -->

                      <!-- <dataset-pager /> -->
                      <pagination
                        :data="List_Suplier"
                        class="float-right"
                        :limit="1"
                        @pagination-change-page="fetchSupList"
                      />
                    </div>
                  </dataset>
                  <dataset-show class="mb-2" v-model="defaultShow" @changed="onShowChange" :ds-show-entries="defaultShow" />

                  <!-- <table class="table table-centered table-nowrap"> -->
                  <!--   <v-table
                    class="table table-hover"
                    :data="List_Suplier"
                    selectionMode="single"
                    selectedClass="table-info"
                    @selectionChanged="selectedRows = $event"
                  >
                  <thead slot="head" class="thead-light" >
                    <tr>
                      <th>Prestataire</th>
                      <th>Email	</th>
                      <th>Status entreprise	</th>
                      <th>Télèphone</th>
                    </tr>
                  </thead>
                  <tbody slot="body" slot-scope="{displayData}">

                    <v-tr v-for="row in displayData" :key="row.id" :row="row">
                      <td>{{ row.prestname }}</td>
                      <td>{{ row.email }}</td>
                      <td>{{ row.statut_entreprise }}</td>
                      <td>{{ row.phone }}</td>     
                    </v-tr>
                  </tbody>
                </v-table> -->
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <ul id="StickyGSI">
            <li>
              <button
                type="button"
                :disabled="disabledAdd"
                @click="addBtn"
                class="btn btn-addGSI rounded-pillGSI float-right mr-1 mt-1"
              >
                <i class="fas fa-plus text-white"></i>
              </button>
            </li>
            <li>
              <button
                type="button"
                :disabled="disabledUpdate"
                @click="editBtn"
                class="btn btn-editGSI rounded-pillGSI float-right mr-1 mt-1"
              >
                <i class="fas fa-pencil-alt text-white"></i>
              </button>
            </li>
            <li>
              <button
                type="button"
                :disabled="disabledUpdate"
                @click="deleteBtn"
                class="btn btn-deleteGSI rounded-pillGSI float-right mr-1 mt-1"
              >
                <i class="fas fa-trash-alt text-white" style=""></i>
              </button>
            </li>
            <li>
              <button
                type="button"
                class="d-none btn btn-uploadGSI rounded-pillGSI float-right mr-1 mt-1"
              >
                <i class="fas fa-file-upload  text-white"></i>
              </button>
            </li>
            <li>
              <button
                type="button"
                class="d-none btn btn-downloadGSI rounded-pillGSI float-right mr-1 mt-1"
              >
                <i class="fas fa-file-download text-white"></i>
              </button>
            </li>
            <li>
              <button
                type="button"
                class="d-none btn btn-printGSI rounded-pillGSI float-right mr-1 mt-1"
              >
                <i class="fas fa-print"></i>
              </button>
            </li>
          </ul>
          <div class="card-body">
            <h4 class="card-title">
              Fiche Prestataire
            </h4>
            <p class="card-title-desc">
              Utiliser cette partie pour identifier, modifier, ou consulter un
              Prestataire.
            </p>
            <b-tabs content-class="p-3 text-muted" v-model="tableindex">
              <b-tab active class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block" @click="tableindex = 0"
                    >Infos Générales</span
                  >
                </template>
                <global-supliers-infos
                  @globalCancelBtn="clearGenInfo = $event"
                  @resetBtn="resetBtn = $event"
                  @resetBtnDone="resetBtnProps = $event"
                  :resetBtnProps="resetBtnProps"
                  :addBtnProps="addBtnProps"
                  :editBtnProps="editBtnProps"
                  :deleteBtnProps="deleteBtnProps"
                  :clearGenInfo="clearGenInfo"
                  :firstStepDisabled="firstStepDisabledDone"
                  :supplier="currentSelectedSupplier"
                  @deleteSupp="deleteSupp = $event"
                  @nextIndex="nextIndex = $event"
                  @globalForm="globalForm = $event"
                  :selectedSupplier="selectedSupplier"
                />
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block" @click="tableindex = 1"
                    >Infos Réglementaires</span
                  >
                </template>
                <regular-supliers-infos
                  @regularCancelBtn="clearGenInfo = $event"
                  :clearGenInfo="clearGenInfo"
                  @resetBtn="resetBtn = $event"
                  @resetBtnDone="resetBtnProps = $event"
                  :resetBtnProps="resetBtnProps"
                  :addBtnProps="addBtnProps"
                  :editBtnProps="editBtnProps"
                  :deleteBtnProps="deleteBtnProps"
                  :uuid="uuid"
                  @firstStepDisabled="firstStepDisabled = $event"
                  @suplierid="id = $event"
                  :supplier="currentSelectedSupplier"
                  @deleteSupp="deleteSupp = $event"
                  @nextIndex="nextIndex = $event"
                  @gotofirstStep="gotofirstStep = $event"
                  :GeneralData="this.globalForm"
                  @refrechTable="refrechTable = $event"
                  :selectedSupplier="selectedSupplier"
                />
              </b-tab>

              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Infos Staff</span>
                </template>
                <staff-supliers-infos
                  :supID="id"
                  :currentSelectedStaffs="currentSelectedStaffs"
                  @refrechTable="refrechTable = $event"
                  :selectedSupplier="selectedSupplier"
                />
              </b-tab>

              <!--b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                   <span class="d-none d-sm-inline-block"
                    >Importer Staff</span
                  > 
                    
                </template>
                  <staff-import-infos :columns="columns" ressource="staffs" />
              </b-tab-->
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style>
.btn-import {
  margin-top: -15px;
  margin-bottom: -10px;
}
</style>